import { roles, permisssions } from './rolesAndPermissions';

export const routePaths: any = {
  // PUBLIC
  auth: {
    forgotPassword: '/forgot-password',
    login: '/login',
    resetPassword: '/reset-password',
    resetPasswordSuccess: '/reset-password-success',
    changePassword: '/change-password',
    signUp: '/sign-up',
  },

  // GLOBAL
  shift: '/shift',
  notFound: '/404',

  // PRIVATE
  home: '/',
  dashboard: '/dashboard',
  logout: '/logout',
  whoIsWorking: {
    route: '/who-is-working',
    access: {
      roles: [roles.ADMIN],
      rolesWithPermission: [
        {
          role: roles.EMPLOYEE,
          permissions: [permisssions.WHOISWORKING],
        },
        {
          role: roles.MANAGER,
          permissions: [permisssions.WHOISWORKING],
        },
      ],
    },
  },
  employeeTimeClock: {
    route: '/employee-time-clock',
    access: {
      roles: [roles.MANAGER, roles.EMPLOYEE],
    },
  },
  employees: {
    base: {
      route: '/employees',
      access: {
        roles: [roles.ADMIN, roles.MANAGER],
      },
    },
    add: '/employees/add',
    edit: '/employees/edit/:id',
    detail: '/employees/:id',
    employeeGroupDetail: '/employees/group/:id',
  },
  messageBlast: {
    base: {
      route: '/messages',
      access: {
        roles: [roles.ADMIN, roles.MANAGER],
      },
    },
    add: '/messages/add',
    view: '/messages/view/:id',
    recievedMessages: '/messages/recieved',
    sentMessages: '/messages/sent',
  },
  cherryPickShift: {
    base: {
      route: '/cherry-pick-shift',
    },
  },
  jobsAndTasks: {
    base: {
      route: '/jobs-tasks',
      access: {
        roles: [roles.ADMIN],
        rolesWithPermission: [
          {
            role: roles.EMPLOYEE,
            permissions: [permisssions.MANAGEJOBS],
          },
          {
            role: roles.MANAGER,
            permissions: [permisssions.MANAGEJOBS],
          },
        ],
      },
    },
    jobs: '/jobs-tasks/jobs',
    addJob: '/jobs-tasks/jobs/add',
    editJob: '/jobs-tasks/jobs/edit/:id',
    jobDetail: '/jobs-tasks/jobs/:id',
    tasks: '/jobs-tasks/tasks',
    addTask: '/jobs-tasks/tasks/add',
    editTask: '/jobs-tasks/tasks/edit/:id',
  },
  timeOff: {
    base: {
      route: '/time-off',
    },
    requests: {
      route: '/time-off/requests',
      access: [roles.ADMIN, roles.MANAGER],
      accept: '/time-off/requests/accepted',
      reject: '/time-off/requests/rejected',
    },
  },
  companySettings: {
    base: {
      route: '/company-settings',
      access: {
        roles: [roles.ADMIN],
      },
    },
    general: '/company-settings/general',
    notifications: '/company-settings/notifications',
    payrollAndOvertime: {
      base: {
        route: '/company-settings/payroll-and-overtime',
      },
      payroll: '/company-settings/payroll-and-overtime/payroll',
      add: '/company-settings/payroll-and-overtime/add',
      edit: '/company-settings/payroll-and-overtime/edit/:id',
      view: '/company-settings/payroll-and-overtime/view/:id',
    },
    californiaBreaks: {
      base: {
        route: '/company-settings/california-breaks',
      },
      settings: '/company-settings/california-breaks/settings',
      addEmployees: '/company-settings/california-breaks/add-employees',
    },
    report: '/company-settings/report',
    breaks: '/company-settings/breaks',
    auditLogs: '/company-settings/audit-logs',
    facialRecognition: '/company-settings/facial-recognition',
    schedule: '/company-settings/schedule',
    timeRounding: '/company-settings/time-rounding',
    shortestDistance: '/company-settings/shortest-distance',
    segmentedTracking: '/company-settings/segmented-tracking',
    signatures: '/company-settings/signatures',
    trackerChecklist: '/company-settings/tracker-checklist',
    commuterMileage: '/company-settings/commuter-mileage',
    evvSettings: '/company-settings/evv',
  },
  // accountBilling: { route: '/account-billing', access: { roles: [roles.ADMIN] } },
  accountBilling: {
    base: {
      route: '/account-billing',
      access: {
        roles: [roles.ADMIN],
      },
    },
    upgrade: '/account-billing/upgrade',
    checkout: '/account-billing/checkout',
  },
  thankYou: '/thank-you',
  timesheetAndMileage: {
    base: {
      route: '/timesheet-mileage',
    },
    all: '/timesheet-mileage/all',
    pending: '/timesheet-mileage/pending',
    approved: '/timesheet-mileage/approved',
    addTimesheet: {
      route: '/timesheet-mileage/add',
      access: {
        roles: [roles.ADMIN],
        rolesWithPermission: [
          {
            role: roles.MANAGER,
            permissions: [permisssions.MANAGETIMESHEETS],
          },
          {
            role: roles.EMPLOYEE,
            permissions: [permisssions.MANAGETIMESHEETS],
          },
        ],
      },
    },
    editTimesheet: {
      route: '/timesheet-mileage/edit/:id',
      access: {
        roles: [roles.ADMIN],
        rolesWithPermission: [
          {
            role: roles.MANAGER,
            permissions: [permisssions.MANAGETIMESHEETS],
          },
          {
            role: roles.EMPLOYEE,
            permissions: [permisssions.MANAGETIMESHEETS],
          },
        ],
      },
    },
    quickTimeEntry: '/timesheet-mileage/quick-time-entry',
    map: '/timesheet-mileage/map/:id',
    segmentedTracking: '/timesheet-mileage/segmented-tracking/:id',
    timesheetDetail: '/timesheet-mileage/detail/:id',
  },
  referAndEarn: '/refer-and-earn',
  scheduling: { base: '/scheduling', add: '/scheduling/add', edit: '/scheduling/edit/:id' },
  reports: {
    base: {
      route: '/reports',
      access: {
        roles: [roles.ADMIN],
        rolesWithPermission: [
          {
            role: roles.EMPLOYEE,
            permissions: [permisssions.ACCESSREPORTS],
          },
          {
            role: roles.MANAGER,
            permissions: [permisssions.ACCESSREPORTS],
          },
        ],
      },
    },
    employee: '/reports/employee',
    mileage: '/reports/mileage',
    dateRange: '/reports/date-range',
    totalOnly: '/reports/total-only',
    job: '/reports/job',
    break: '/reports/break',
    californiaDailySignOffReport: '/reports/californiaDailySignOffReport',
    californiaPremiumPayReport: '/reports/premiumPayReports',
    scheduleVsHours: '/reports/schedule-vs-hours',
    dailyFlatRateByTotal: '/reports/daily-flat-rate-by-total',
    dailyFlatRateByEmployee: '/reports/daily-flat-rate-by-employee',
    weekTotals: '/reports/week-totals',
    xeroReport: '/reports/xero',
    segmentedTrackingReport: '/reports/segmented-tracking',
    scheduleReportByUser: '/reports/schedules',
    task: '/reports/task',
  },
  integrations: {
    base: {
      route: '/integrations',
      access: { roles: [roles.ADMIN] },
    },
    lists: '/integrations/list',
    redirect: '/integrations/redirect',
    zapier: '/integrations/zapier',
    publicApi: '/integrations/public-api',
    paycheck: '/integrations/paycheck',
    quickbook: {
      base: '/integrations/quickbook',
      auth: '/integrations/quickbook/auth2',
      mapping: '/integrations/quickbook/mapping',
      mappingTabs: '/integrations/quickbook/mapping-tabs',
    },
    adp: {
      base: '/integrations/adp',
      auth: '/integrations/adp/auth2',
      mapping: '/integrations/adp/mapping',
    },
    gusto: {
      base: '/integrations/gusto',
      auth: '/integrations/gusto/auth2',
      companySelect: '/integrations/gusto/select-company',
      mapping: '/integrations/gusto/mapping',
    },
    rippling: {
      base: '/integrations/rippling',
      auth: '/integrations/rippling/auth2',
      mapping: '/integrations/rippling/mapping',
    },
    quickbookDesktop: {
      base: '/integrations/quickbookDesktop',
      mapping: '/integrations/quickbookDesktop/mapping',
      mappingTabs: '/integrations/quickbookDesktop/mapping-tabs',
    },
    paychex: {
      base: '/integrations/paychex',
      mapping: '/integrations/paychex/mapping',
    },
    paylocity: {
      base: '/integrations/paylocity',
      mapping: '/integrations/paylocity/mapping',
      exportDetail: '/integrations/paylocity/export-detail',
    },
    xero: {
      base: '/integrations/xero',
      mapping: '/integrations/xero/mapping',
    },
    paychexApi: {
      base: '/integrations/paychex-api',
      mapping: '/integrations/paychex-api/mapping',
    },
    viventium: {
      base: '/integrations/viventium',
      mapping: '/integrations/viventium/mapping',
    },
    RUNByADP: {
      base: '/integrations/run-by-adp',
      mapping: '/integrations/run-by-adp/mapping',
    },
    sandataIndiana: {
      base: '/integrations/sandata-indiana',
      mapping: '/integrations/sandata-indiana/mapping',
    },
    pimsy: {
      base: '/integrations/pimsy',
      mapping: '/integrations/pimsy/mapping',
    },
    everee: {
      base: '/integrations/everee',
      mapping: '/integrations/everee/mapping',
    },
  },
  profile: '/profile',
  ideasFeedback: '/ideas-feedback',
  notification: {
    base: '/notification',
    detail: '/notification/:id',
  },
  upsale: {
    base: '/upsale',
    jobs: '/upsale/jobs',
    message_blast: '/upsale/message-blast',
    scheduling: '/upsale/scheduling',
    time_off: '/upsale/time-off',
    integrations: '/upsale/integrations',
    suggested_mileage: '/upsale/suggested-mileage',
  },
  evv: {
    base: '/evv',
    employees: '/evv/evv-employees',
    addEmployee: '/evv/evv-employees/add',
    editEmployee: '/evv/evv-employees/edit/:id',
    clients: '/evv/evv-clients',
    addClient: '/evv/evv-clients/add',
    editClient: '/evv/evv-clients/edit/:id',
    visits: '/evv/evv-visits',
  },
};
